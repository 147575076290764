import React, { useEffect } from 'react';
import { MdOutlineError } from "react-icons/md";
import '../styles/MailFailure.css'
import { Link } from 'react-router-dom';

const MailFailure = () => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
          // Redirect to the desired URL after 15 seconds
          window.location.replace('/');
        }, 10000);
    
        // Clear the timeout if the component is unmounted
        return () => clearTimeout(timeoutId);
      }, []);
    return (
        <div className='failure-email'>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12">
                        <div className="failure-page text-center">
                            <div className="verified">
                                <div className="verified-icon"><MdOutlineError /></div>
                                <h1>Error</h1>
                                <p>Your submission has been Failed.</p>
                                <Link to='/'><button>Back to home</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MailFailure
