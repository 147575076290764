import React from 'react'
import '../styles/Template.css'
import Navbar from './Navbar'
import std from '../assets/Images/std.jpg'
import picture1 from '../assets/Images/Picture1.png'
import picture2 from '../assets/Images/Picture2.png'
import picture3 from '../assets/Images/Picture3.png'
import 'fontsource-poppins'; // Import Poppins font CSS
import { useState } from 'react';





const Template = () => {
  const [inputFields, setInputFields] = useState([{ header: '', value: '' }]);

  const handleAddFields = (e) => {
    e.preventDefault(); // Prevent form submission
    const newInputFields = [...inputFields];
    newInputFields.push({ header: '', value: '' });
    setInputFields(newInputFields);
  };

  const handleRemoveFields = (index, e) => {
    e.preventDefault(); // Prevent form submission
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const handleInputChange = (index, event) => {
    const newInputFields = [...inputFields];
    newInputFields[index][event.target.name] = event.target.value;
    setInputFields(newInputFields);
  };
  
  return (
    <div className='total-sec'>
      <Navbar/>
      <div className="container-fluid banner_sec">

    <div className='over-banner'>
        <div className="container">
    <div className="row">
      <div className="">
        <div className="main-banner">
          <div className="sub-banner">
            <h1 className='sample'>Pay-Now</h1>
            {/* <h3>SUB SECTION PAGE</h3>
            <button className="buttn">CLICK</button> */}
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
</div>


{/* <div className='form-head'>
<div>
  <form action="">
    <div className='min-forms'>
      <label>Header</label>
      <input type="text" placeholder='Type Header Name' />
      <button>SUBMIT</button>
    </div>
  </form>
</div>
</div> */}



<div className='all-sets'>
  <form action="">
    <div className='main-content'>
      <div className='search-base'>
        {/* <h6>(1)</h6> */}
        <input type="text" placeholder='Enter the contents'/>
        {/* <button>+</button> */}
      </div>






    

    {/* <div className='check-content'>
      <input type="text" placeholder='header' />
      <input type="text" placeholder='Value'/>
      <button id='plus_btn'>+</button>
    </div> */}

{/* <div className="check-content">
      <form>
        {inputFields.map((inputField, index) => (
          <div key={index}>
            <input
              type="text"
              name="header"
              placeholder="Header"
              value={inputField.header}
              onChange={(event) => handleInputChange(index, event)}
            />
            <input
              type="text"
              name="value"
              placeholder="Value"
              value={inputField.value}
              onChange={(event) => handleInputChange(index, event)}
            />
            {index === 0 ? (
              <button id="plus_btn" onClick={handleAddFields}>
                +
              </button>
            ) : (
              <button id='minus_btn' type="button" onClick={(e) => handleRemoveFields(index, e)}>
                -
              </button>
            )}
          </div>
        ))}
      </form>
    </div> */}










    
    
    {/* <div className="align-content">
      <input type="text" placeholder='UPI ID'/>
      <input type="email" placeholder='Enter mail' />
      <button>+</button>
    </div> */}


    {/* ................................................................................. */}

    {/* <div className="imgs">
      <input type="file" placeholder='Upload file'/>
    </div> */}
    {/* <div class="file-input">
      <input
        type="file"
        name="file-input"
        id="file-input"
        class="file-input__input"
      />
      <label class="file-input__label" for="file-input">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="upload"
          class="svg-inline--fa fa-upload fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
          ></path>
        </svg>
        <span>Upload file</span></label
      >
    </div> */}

    {/* ........................................................................................ */}

    <div className="submit">
      <button>SUBMIT</button>
    </div>

    </div>
  </form>
</div>




{/* <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
          <div className="main-name">
            <div className="namesec">
              <h1>Course Outcomes</h1>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. natus repellat earum expedita dignissimos sed consequatur nam.</p>
            </div>
          </div>
        </div>
      </div>
    </div>



<div className="container-fluid segment d-flex justify-content-center align-items-center">
<div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          
          <div className="page">
            <div className="page_header">
              <div className="page_sub">
                <h1>IELTS CONTENTS</h1>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic incidunt odio veniam, aut voluptas,
                  architecto nobis eius error dicta nihil nemo quam quo nesciunt qui ex aperiam laudantium. Dolorum,
                  nobis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias libero officia facere ratione
                  blanditiis dignissimos labore, quisquam pariatur repellendus. Vero sapiente pariatur culpa obcaecati
                  incidunt, impedit ad! Provident, esse voluptate! Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
                  Impedit, eaque deleniti officia laboriosam repellat odit ut aperiam atque consequatur, architecto nostrum fuga, 
                  omnis iusto rerum autem. Non doloribus magni explicabo?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div className="page_subsec">
            <img src={std} alt="" />
          </div>
        </div>
      </div>
    </div>
    </div>



   

<div className='container-fluid box-sec'>

  <div class="container text-center head_para">
    <h1 id='feature'>Features</h1>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae atque blanditiis 
      ipsam doloremque velit ullam quasi nisi reprehenderit.
    </p>
  </div>
  
  <div class="container">
    <div class="row">
      <div class="col-md-4 contains1">
        <div class="card value1">
          <img src={picture1} class="card-img-top img1" alt="" />
          <div class="card-body">
            <h1 class="card-title course1">Free course</h1>
            <p class="card-text lorem1">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa 
              psum similique deserunt rem cupiditate commodi voluptatibus rati
              one quis,quos harum tenetur omnis fugit qui pariatur architecto 
              veritatis fuga ducimus.
            </p>
          </div>
        </div>
      </div>
  
      <div class="col-md-4 contains2">
        <div class="card value2">
          <img src={picture2} class="card-img-top" alt="" />
          <div class="card-body">
            <h1 class="card-title course2">General Course</h1>
            <p class="card-text lorem2">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa 
              psum similique deserunt rem cupiditate commodi voluptatibus rati
              one quis,quos harum tenetur omnis fugit qui pariatur architecto 
              veritatis fuga ducimus.</p>
          </div>
        </div>
      </div>
  
      <div class="col-md-4">
        <div class="card value3">
          <img src={picture3} class="card-img-top" alt="" />
          <div class="card-body">
            <h1 class="card-title course3">Academic Course</h1>
            <p class="card-text lorem3">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa 
              psum similique deserunt rem cupiditate commodi voluptatibus rati
              one quis,quos harum tenetur omnis fugit qui pariatur architecto 
              veritatis fuga ducimus.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div> */}

   
   


    </div>
  )
}

export default Template
