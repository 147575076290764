import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import '../styles/Slider.css';

// Import required modules
import { FreeMode, Pagination } from 'swiper/modules';

export default function Slider() {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    // Load the YouTube iframe API script
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      const iframeElements = document.querySelectorAll('.youtube-iframe');
    
      // Convert the NodeList to an array
      const iframeArray = Array.from(iframeElements);
    
      // Create a player for each YouTube video
      const newPlayers = iframeArray.map((iframe) => {
        const videoId = iframe.getAttribute('data-video-id');
        const player = new window.YT.Player(iframe, {
          videoId,
          events: {
            onReady: (event) => {
              // Play the video when ready
              event.target.playVideo();
            },
          },
        });
        return player;
      });
    
      setPlayers(newPlayers);
    };

    return () => {
      // Clean up when the component unmounts
      delete window.onYouTubeIframeAPIReady;
    };
  }, []);

  const playFullScreen = (index) => {
    if (players[index]) {
      players[index].playVideo();

      // Request full-screen mode
      const iframe = players[index].getIframe();
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen(); // Firefox
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen(); // Chrome, Safari, and Opera
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen(); // IE/Edge
      }
    }
  };
  return (
    <div id='all'>
      <h1 id='align-head'>Testimonials</h1>
      <div id='contents-slides'>
        
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
            
              <iframe
                onClick={() => playFullScreen(0)}
                className="youtube-iframe"
                id='video1'
                data-video-id="1QGhH2i8_U4"
                width="90%"
                height="315"
                src="https://www.youtube.com/embed/PfU4tvDn7gY?si=qEfdTriFPhT1xa-d"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            
          </SwiperSlide>
          <SwiperSlide>
            
              <iframe
                onClick={() => playFullScreen(0)}
                className="youtube-iframe"
                id='video2'
                data-video-id="1QGhH2i8_U4"
                width="90%"
                height="315"
                src="https://www.youtube.com/embed/UadxnhEgDzo?si=fg5tvtoJjurzezDC"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            
          </SwiperSlide>
  
          <SwiperSlide>
            
              <iframe
                onClick={() => playFullScreen(0)}
                className="youtube-iframe"
                id='video3'
                data-video-id="1QGhH2i8_U4"
                width="90%"
                height="315"
                src="https://www.youtube.com/embed/jMjCE7ZiScY?si=bctsDhgBKOHwaxIC"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            
          </SwiperSlide>
  
          <SwiperSlide>
            
              <iframe
                onClick={() => playFullScreen(0)}
                className="youtube-iframe"
                id='video4'
                data-video-id="1QGhH2i8_U4"
                width="90%"
                height="315"
                src="https://www.youtube.com/embed/dU-fboZrh1g?si=EGARIRY5KP9RoPDO"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            
          </SwiperSlide>
  
          <SwiperSlide>
            
              <iframe
                onClick={() => playFullScreen(0)}
                className="youtube-iframe"
                id='video5'
                data-video-id="1QGhH2i8_U4"
                width="90%"
                height="315"
                src="https://www.youtube.com/embed/li-M-QQOz4s?si=oawA7JRTMxNLnIDo"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            
          </SwiperSlide>
  
          <SwiperSlide>
            
            <iframe
              onClick={() => playFullScreen(0)}
              className="youtube-iframe"
              id='video6'
              data-video-id="1QGhH2i8_U4"
              width="90%"
              height="315"
              src="https://www.youtube.com/embed/77X5WOHwQ5c?si=SKr7JuX6lVTiZnEU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          
        </SwiperSlide>
          
        <SwiperSlide>
            
            <iframe
              onClick={() => playFullScreen(0)}
              className="youtube-iframe"
              id='video7'
              data-video-id="1QGhH2i8_U4"
              width="90%"
              height="315"
              src="https://www.youtube.com/embed/e0XVOQ5mkDw?si=IhppeCb6d0JhmTVq"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          
        </SwiperSlide>
          
        <SwiperSlide>
            
            <iframe
              onClick={() => playFullScreen(0)}
              className="youtube-iframe"
              id='video8'
              data-video-id="1QGhH2i8_U4"
              width="90%"
              height="315"
              src="https://www.youtube.com/embed/hrt0DST6F-c?si=KZ1lCIcnAmbPdGFX"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          
        </SwiperSlide>
          
        <SwiperSlide>
            
            <iframe
              onClick={() => playFullScreen(0)}
              className="youtube-iframe"
              id='video9'
              data-video-id="1QGhH2i8_U4"
              width="90%"
              height="315"
              src="https://www.youtube.com/embed/1QGhH2i8_U4?si=JUlT2DIhaLxbDxWV"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          
        </SwiperSlide>
  
        <SwiperSlide>
            
            <iframe
              onClick={() => playFullScreen(0)}
              className="youtube-iframe"
              id='video10'
              data-video-id="1QGhH2i8_U4"
              width="90%"
              height="315"
              src="https://www.youtube.com/embed/tg3bUrtv91k?si=k5KXwAWpH4Zuouxj" 
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          
        </SwiperSlide>
          
          
      </Swiper>
    </div>
    </div>
  );
}