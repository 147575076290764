import React, { useState, useEffect, useRef } from 'react';
import Navbar from './Navbar'
import ReCAPTCHA from "react-google-recaptcha";


import emailjs from '@emailjs/browser';
// import styled from "styled-components";

import '../styles/HomePage.css'
import { BsDisplay, BsGraphUpArrow, BsPlayCircle, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaVideo, } from "react-icons/fa";
import { FaChalkboardUser } from "react-icons/fa6";
import { LuClipboardSignature } from "react-icons/lu";
import { TfiWrite } from "react-icons/tfi";
import { AiFillStar } from "react-icons/ai";
import { IoClose } from "react-icons/io5";



// import ReCAPTCHA from "react-google-recaptcha"; // google recaptcha import

import video_bg from '../assets/Images/video_bg.png';
import men1 from '../assets/Images/men1.jpg';
import men2 from '../assets/Images/men2.jpg';
import men3 from '../assets/Images/men3.jpg';
import men4 from '../assets/Images/men4.jpg';
import men5 from '../assets/Images/men5.jpg';
import men6 from '../assets/Images/men6.jpg';
import men7 from '../assets/Images/men7.jpg';
import person3 from '../assets/Images/person3.jpg';
import { Link, useNavigate } from 'react-router-dom';
import 'fontsource-poppins'; // Import Poppins font CSS
import Footer from './Footer';
import GoogleRecaptcha from './GoogleRecaptcha';
import Slider from './Slider';
import Sliders from './Sliders';
import WhatsAppButton from './WhatsAppButton';


const HomePage = () => {
  const navigate = useNavigate()
  // ----------------------email---------------------

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [location, setLocation] = useState('')
  const [locationError, setLocationError] = useState('')

  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState('')


  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();



  //   let isValid = true;

  //   if (!name.trim()) {
  //     setNameError('Name is required');
  //     isValid = false;
  //   } else if (!/^[A-Za-z\s]+$/.test(name)) {
  //     setNameError('Invalid name');
  //     isValid = false;
  //   } else {
  //     setNameError('');
  //   }

  //   if (!phone.trim()) {
  //     setPhoneError('Mobile number is required');
  //     isValid = false;
  //   } else if (!/^\d{10}$/.test(phone)) {
  //     setPhoneError('Invalid mobile number');
  //     isValid = false;
  //   } else {
  //     setPhoneError('');
  //   }

  //   if (!location.trim()) {
  //     setLocationError('Location name is required');
  //     isValid = false;
  //   } else if (!/^[A-Za-z\s]+$/.test(location)) {
  //     setLocationError('Invalid location name');
  //     isValid = false;
  //   } else {
  //     setLocationError('');
  //   }


  //   if (isValid) {
  //     emailjs.sendForm('service_mmp4fdh', 'template_2cztydz', form.current, 'IoXdulWI4OWCKytb3')
  //       .then((result) => {
  //         console.log(result.text);
  //         alert("message sent successfully")
  //       }, (error) => {
  //         console.log(error.text);
  //       });
  //   }
  // };




  // ----------------------email---------------------





  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  };


  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));

  };

  const next = () => {
    const slider = sliderRef.current;
    const testimonials = slider.querySelectorAll('.testimonial-container');
    const firstTestimonial = testimonials[0];
    const nextTestimonial = testimonials[1];

    if (nextTestimonial) {
      slider.style.transition = 'transform 0.5s ease-in-out';
      slider.style.transform = `translateX(-${firstTestimonial.offsetWidth}px)`;

      setTimeout(() => {
        slider.appendChild(firstTestimonial);
        slider.style.transition = 'none';
        slider.style.transform = 'translateX(0)';
      }, 500);
    }
  };

  const back = () => {
    const slider = sliderRef.current;
    const testimonials = slider.querySelectorAll('.testimonial-container');
    const lastTestimonial = testimonials[testimonials.length - 1];
    const firstTestimonial = testimonials[0];

    slider.insertBefore(lastTestimonial, firstTestimonial);
    slider.style.transition = 'none';
    slider.style.transform = `translateX(-${firstTestimonial.offsetWidth}px)`;

    setTimeout(() => {
      slider.style.transition = 'transform 0.5s ease-in-out';
      slider.style.transform = 'translateX(0)';
    }, 0);
  };


  const clicks = (event) => {
    event.preventDefault();
    const siteFooter = document.getElementById("site-footer");
    if (siteFooter) {
      siteFooter.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  const onChange = () => {

  }

  return (
    <div>
      <Navbar />

      <div className="main-div">
        <div className="banner">
          <div className="banner-header">
            <div className='banner-header-wrapper'>
              <span className='bh-subtitle'>GET YOUR TARGET SCORE</span>
              <h2 className='bh-title'>IN JUST ONE ATTEMPT</h2>
              <h4 className='bh-text'>Top IELTS Training Platform in the World!</h4>
            </div>

            <div className="banner-btn">
              <div className='banner-btn-wrapper'>
                <Link to={'https://courses.ieltsmaster.com/'}><button type="submit" className="course-btn">COURSES</button></Link>
                <button onClick={() => {
                  window.location.href = 'https://www.ieltsmaster.com/trial-class/';
                }} type='submit' className='trial-btn'>TRIAL CLASSES</button>
              </div>
            </div>
          </div>
          {/* <Link to='https://web.whatsapp.com/'><button className='whatsapp'><FaWhatsappSquare/></button></Link> */}
          <div className='whatsapp'>
            <WhatsAppButton />
          </div>
        </div>

        <div className="about-section">
          <div className="about-text">
            <div className="about-header">
              <h5>WHY IELTS MASTER?</h5>
              <h1>About IELTS Master - IELTS Coaching</h1>
            </div>
            <div className="about-para">
              <p>IELTS Master is one of the leading IELTS coaching platforms in the world; assisting students with free study materials, online video lectures, live video sessions, online mock tests and live reporting system with detailed feedback. We operate with a team of highly qualified instructors from all over the world. our success rate is above 90% with students achieving their target score in their first attempt. Now it is your turn!!
              </p>
              {/* <button className='cf-submit-btn' onClick={clicks}>REQUEST A Quick Callback</button> */}
              <div className='course-feature-video'>
                <div className="course-features-right">
                  <div className="video-container">
                    <img src={video_bg} alt="" />
                    <button onClick={openModal} className='play-btn'><BsPlayCircle /></button>

                    {isModalOpen && (
                      <div className="modal">
                        <button className='close-button' onClick={closeModal}><IoClose /></button>
                        <div onClick={closeModal} className="overlay"></div>
                        <div className="modal-content">
                          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/01y-SPjNOH8?si=wtxiNKqAuW3HplQ5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="callback-form-section">
            <div className='callback-form-container'>
              <div className='cf-header'>
                <span className='bh-subtitlez'>REQUEST A</span>
                <h2 className='bh-titlez'>Quick Callback</h2>
                <h5 className='cf-textz'>Our team will call you soon!</h5>
              </div>


              <div className='callback-form-wrapper'>

                <form method="POST" action="https://backend.ieltsmaster.com/IELTS-Trialclass-backend/">
                  <label className='cf-label'>Your Name <span>*</span></label>
                  <input type="text" name='name' className='form-control border border-secondary' value={name} onChange={(e) => setName(e.target.value)} />
                  {nameError && <p className="error-message">{nameError}</p>}

                  <label className='cf-label'>Phone (With Country Code) <span>*</span></label>
                  <input type="text" name='phone' className='form-control border border-secondary' value={phone} onChange={(e) => setPhone(e.target.value)} />
                  {phoneError && <p className="error-message">{phoneError}</p>}

                  <label className='cf-label'>Location <span>*</span></label>
                  <input type="text" name='location' className='form-control border border-secondary' value={location} onChange={(e) => setLocation(e.target.value)} />
                  {locationError && <p className="error-message">{locationError}</p>}
                  

                  <button className='cf-submit-btn' name='submit' type='submit'>SUBMIT</button>

                </form>

              </div>
            </div>
          </div>


          
        </div>

        <div className="course-section" id='features'>
          <div className="course-container">
            <div className='course-wrapper'>
              <div className="course-header">
                <span className='bh-subtitle2'>KNOW MORE...</span>
                <h2 className='bh-title2'>Our Course Attractions</h2>
              </div>
              <div className="course-features">
                <div className='course-feature-text'>
                  <div className="course-features-left">
                    <div className="features">
                      <div className="feature-icon">
                        <BsDisplay />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>IELTS Master Student Portal</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>Our portal is a repository of , practice lessons, essays, exercises, vocabulary, and collocations.</p>
                        </div>
                      </div>
                    </div>

                    <div className="features">
                      <div className="feature-icon">
                        <FaVideo />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>Live Interactive Sessions</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>This session provides a comprehensive view of the patterns and strategies to tackle all questions.</p>
                        </div>
                      </div>
                    </div>

                    <div className="features">
                      <div className="feature-icon">
                        <LuClipboardSignature />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>Practices and Detailed feedback</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>You can get access to the exhaustive study materials for practices and exercises with feedback</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="course-features-middle">
                    <div className="features">
                      <div className="feature-icon">
                        <FaChalkboardUser />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>Personal Mentor Support</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>The mentor will give guidelines about your classes, practice lessons, evaluation, and feedback</p>
                        </div>
                      </div>
                    </div>

                    <div className="features">
                      <div className="feature-icon">
                        <BsGraphUpArrow />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>IELTS Master Student Report Card</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>Students can access the report card to know the target score, the current score, and the feedback.</p>
                        </div>
                      </div>
                    </div>

                    <div className="features">
                      <div className="feature-icon">
                        <TfiWrite />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>MOCK TEST</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>The simulated IELTS practice tests will enable students to get acquainted with the real test conditions.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='course-feature-video'>
                  <div className="course-features-right">
                    <div className="video-container">
                      <img src={video_bg} alt="" />
                      <button onClick={openModal} className='play-btn'><BsPlayCircle /></button>

                      {isModalOpen && (
                        <div className="modal">
                          <button className='close-button' onClick={closeModal}><IoClose /></button>
                          <div onClick={closeModal} className="overlay"></div>
                          <div className="modal-content">
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/01y-SPjNOH8?si=wtxiNKqAuW3HplQ5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="review-section">
          <div className="review-header">
            <h6>Success Stories...</h6>
          </div>

          <div className="review-testimonial-slider">
            <div className="slider-container">
              <div className='slider-control'>
                <button onClick={back}><BsChevronLeft /></button>
              </div>

              <div className="testimonial-slider-wrapper">
                <div className="testimonial-slider" ref={sliderRef}>
                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men1} />
                        <div className="testimonial-info">
                          <h3>Dania</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>UAE</h6>
                        </div>
                      </div>
                      <div id='ports1'><p className="review">IELTS MASTER has helped me immensely to achieve my dream score, especially in Writing and speaking. I thank the whole team of IELTS MASTER.</p></div>
                    </div>
                  </div>
                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men2} />
                        <div className="testimonial-info">
                          <h3>Dr Apoorva</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Hyderabad</h6>
                        </div>
                      </div>
                      <div id='ports2'><p className="review">Their approach to teaching students is systematic and scientific. Really helpful to all working professionals like myself.<strong>Overall Band 8</strong>(L:8 R:8.5 W:7 S:7.5)</p></div>
                    </div>
                  </div>


                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men3} />
                        <div className="testimonial-info">
                          <h3>Addison</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Goa</h6>
                        </div>
                      </div>
                      <div id='ports3'><p className="review"> I am grateful to IELTS Master for helping me achieve my desired score. I recommend these classes to anyone who is targeting to get the best score in IELTS exam.The enthusiasm and hardwork shown by the team...</p></div>
                    </div>
                  </div>

                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men4} />
                        <div className="testimonial-info">
                          <h3>Vignesh</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Kerala</h6>
                        </div>
                      </div>
                      <div id='ports4'><p className="review"> The guidance provided here is very helpful . Provisions provided for practicing listening exercises are very good and helpful. </p></div>
                    </div>
                  </div>

                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men5} />
                        <div className="testimonial-info">
                          <h3>Aaska patel</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Noida</h6>
                        </div>
                      </div>
                      <div id='ports5'><p className="review"> Getting 8 was no less than a dream come true for me. I thank IELTS Master.<strong>Overall Band 8</strong>(L:8 R:8 W:7 S:7)</p></div>
                    </div>
                  </div>

                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men6} />
                        <div className="testimonial-info">
                          <h3>Dr Sajna</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Manjeri</h6>
                        </div>
                      </div>
                      <div id='ports6'><p className="review">IELTS MASTER has helped me immensely to achieve my dream score, especially in Writing and speaking.I thank the whole team of IELTS MASTER.<strong>Overall Band 8</strong>(L:8.5 R:9 W:7 S:7)</p></div>
                    </div>
                  </div>
                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men7} />
                        <div className="testimonial-info">
                          <h3>Vidya Hegde</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Bangalore</h6>
                        </div>
                      </div>
                      <div id='ports7'><p className="review"> I shall recommend IELTS Master to anyone appearing for IELTS. Thank you <strong>Overall Band 8</strong>(L:8.5 R:8 W:7 S:7.5)</p></div>
                    </div>
                  </div>
                  {/* Add more testimonials here */}
                </div>
              </div>

              <div className='slider-control'>
                <button onClick={next}><BsChevronRight /></button>
              </div>
            </div>

          </div>
        </div>


        <div className="faq-section" id='questions'>
          <div className="faq-container">

            <div className="faq-header">
              <span className='bh-subtitle1'>ALL YOU NEED TO KNOW</span>
              <h2 className='bh-title1'>Frequently Asked Questions</h2>
            </div>

            <button className={`accordion ${activeIndex === 0 ? "active" : ""}`} onClick={() => handleAccordionClick(0)}>
              How can I get a 7.8, or 9 band score?
            </button>
            <div className={`panel ${activeIndex === 0 ? "show" : ""}`}>
              <p className='panel-text'>Yes, you can.  You will get the required score by attending the IELTS online classes, practice tests, and feedback from IELTS Master.</p>
            </div>

            <button className={`accordion ${activeIndex === 1 ? "active" : ""}`} onClick={() => handleAccordionClick(1)}>
              Am I eligible to join the IELTS Online course if I don't have good fluency in English?
            </button>
            <div className={`panel ${activeIndex === 1 ? "show" : ""}`}>
              <p className='panel-text'>Yes, you are. First, we will teach you the basic lessons of the language in the Pre IELTS Course, and then you will be sent to the IELTS  Online classes.</p>
            </div>

            <button className={`accordion ${activeIndex === 2 ? "active" : ""}`} onClick={() => handleAccordionClick(2)}>
              Do you provide Listening and Reading practice tests?
            </button>
            <div className={`panel ${activeIndex === 2 ? "show" : ""}`}>
              <p className='panel-text'>Yes, we do, we have a rich collection of exercises for each type of question in Reading and Listening as well as full-length practice tests with reviews in the portal.</p>
            </div>

            <button className={`accordion ${activeIndex === 3 ? "active" : ""}`} onClick={() => handleAccordionClick(3)}>
              Can I get Writing corrections and speaking tests with this course?
            </button>
            <div className={`panel ${activeIndex === 3 ? "show" : ""}`}>
              <p className='panel-text'>Yes, you can. You will get lots of in-depth writing corrections and feedback from faculties in this course besides one to one speaking tests with detailed reviews.</p>
            </div>

            <button className={`accordion ${activeIndex === 4 ? "active" : ""}`} onClick={() => handleAccordionClick(4)}>
              Can I join the course from abroad?
            </button>
            <div className={`panel ${activeIndex === 4 ? "show" : ""}`}>
              <p className='panel-text'>Yes, of course. You can do this course from any part of the world as we have flexible batches that suit your timing.</p>
            </div>

            <button className={`accordion ${activeIndex === 5 ? "active" : ""}`} onClick={() => handleAccordionClick(5)}>
              How can I join this course?
            </button>
            <div className={`panel ${activeIndex === 5 ? "show" : ""}`}>
              <p className='panel-text'> You can join the course in three steps.
                <li className='join-steps'>&nbsp; Find your suitable course from this link; <Link to='https://courses.ieltsmaster.com/fees-guide' className='links'>Courses</Link></li>
                <li className='join-steps'>&nbsp; Make the payment with this link. <Link to='https://courses.ieltsmaster.com/pay-now' className='links'>Pay Now</Link></li>
                <li className='join-steps'>&nbsp; Fill this form; <Link to='https://reports2.ieltsmaster.com/index.php/Student' className='links'>Student Application Form</Link> and then  you will get quick response from our end</li>
              </p>
            </div>

            <button className={`accordion ${activeIndex === 6 ? "active" : ""}`} onClick={() => handleAccordionClick(6)}>
              Do you accept Credit card to make the payment?
            </button>
            <div className={`panel ${activeIndex === 6 ? "show" : ""}`}>
              <p className='panel-text'>Yes, we do.  We accept all cards including debit cards , credit cards, internet banking, and UPI. Please go to this link: <Link className='links'>Pay Now</Link></p>
            </div>

          </div>
        </div>

        {/* ...............................movivg........................................ */}

        {/* <div class="slider">
        <div className='testimonial-headers'><h1>Testimonials</h1></div>
	<div class="slide-track">
    
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/01y-SPjNOH8?si=uTxwH7n-R5bMMZ6h" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/dU-fboZrh1g?si=aBBmIrYR8wUMZK8n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/PfU4tvDn7gY?si=zaKgNQ55ah97vRlE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/hrt0DST6F-c?si=odXoGkIURSCkz0RP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/li-M-QQOz4s?si=bVeTgDcwE2fdKREW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/8c7mcU_JUp8?si=A6vYOnqqIrzqJUFV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/g2JbtqlTK-g?si=Y44RL_0jgv02SPte" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/jMjCE7ZiScY?si=wfQXNJ-PKtG597t-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/mne8boakoc4?si=NHQe9DXYtSZ2wfDH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/77X5WOHwQ5c?si=lMxBclF8F9Acg5dS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/e0XVOQ5mkDw?si=8caC543825OIDyGm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe src="https://www.youtube.com/embed/41-4ZOMK6oE?si=kcXFc-uasZK8Hfbq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' src="https://www.youtube.com/embed/F4bZd_2jIVs?si=MHRwEISqm4zY9vTj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		
	</div>
</div>  */}

        {/* <div className="slider" id='vedios'>
      <div className="testimonial-headers"><h1>Testimonials</h1></div>
      <div className="slide-track">
        {videos.map((videoUrl, index) => (
          <div className="slide" key={index}>
            <iframe
              id={`video${index + 1}`}
              height="300"
              width="560"
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              onClick={() => handleVideoClick(index)}
            ></iframe>
          </div>
        ))}
      </div>
    </div> */}
        {/* <div>
      <div className="slider" id='vedios'>
        <div className="testimonial-headers"><h1>Testimonials</h1></div>
        <div className="slide-track">
          {videos.map((videoUrl, index) => (
            <div className="slide" key={index}>
              <iframe
                id={`video${index + 1}`}
                height="300"
                width="560"
                src={videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                onClick={() => handleVideoClick(videoUrl)}
              ></iframe>
            </div>
          ))}
        </div>
      </div>

      {selectedVideo && (
        <div className="large-video-container">
          <iframe
            id="largeVideo"
            width="800"
            height="450"
            src={selectedVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div> */}
        <div id='setup'><Slider /></div>

        <div id='sub-slides'><Sliders /></div>

        {/* ...............................movivg........................................ */}

      </div>





      <GoogleRecaptcha />

      <Footer />
    </div>
  )
}

export default HomePage
