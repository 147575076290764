
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Template from './component/Template';
import HomePage from './component/HomePage';
import Page from './component/Page';

import Pages from './component/Pages';
import Menu from './component/Menu';
import EditPage from './component/EditPage';
import AdminLogin from './component/AdminLogin';
import { Helmet } from 'react-helmet';
import MailFailure from './component/MailFailure';
import EmailSuccess from './component/EmailSuccess';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Helmet>
          <title>IELTS Training | IELTS Coaching & Preparation India</title>
          <meta
            name='description'
            content='IELTS Training by IELTS Master. We are the Best IELTS Coaching provider in India, specialized IELTS Preparation, IELTS Exams &amp; Courses.'
          />
          <meta name='keyword' content='IELTS coaching platform' />
          <link rel="canonical" href="https://www.ieltsmaster.com/"></link>
        </Helmet>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/template' element={<Template />} />

          <Route path='/page' element={<Page />} />
          <Route path='/pages' element={<Pages />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/edit-page' element={<EditPage />} />
          <Route path='/admin' element={<AdminLogin />} />
          <Route path='/mail-failure' element={<MailFailure />} />
          <Route path='/mail-success' element={<EmailSuccess />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
