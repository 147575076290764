// import React from 'react';
// import { FaWhatsapp } from "react-icons/fa";

// function WhatsAppButton() {
//   const handleWhatsAppButtonClick = () => {
    // Replace '1234567890' with the desired phone number
    // const phoneNumber = '+91 85930 00876';

    // Create the WhatsApp URL
    // const whatsappUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=Hi IELTS Master, I need your assistance for my IELTS preperation!`;


    // Open the WhatsApp chat in a new tab/window
//     window.open(whatsappUrl, '_blank');
//   };

//   return (
//     <button onClick={handleWhatsAppButtonClick}><FaWhatsapp/></button>
//   );
// }

// export default WhatsAppButton;

// import React from 'react';
// import { FaWhatsapp } from "react-icons/fa";
// import { Link } from 'react-router-dom';
// import '../styles/WhatsAppButton.css'

// function WhatsAppButton() {
  

//   return (
//     // <Link to={'https://wa.me/971585411333'} target='_blank'><button><FaWhatsapp/></button></Link>
//     <div className='adding'><button id='addButton'><Link to={'https://wa.me/+918593000876'}><FaWhatsapp/></Link></button></div>
//   );
// }

// export default WhatsAppButton;
import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';
import '../styles/WhatsAppButton.css';

function WhatsAppButton() {
  // Encode the message text to be included in the WhatsApp URL
  const message = encodeURIComponent("Hi IELTS Master, I need your assistance for my IELTS preparation!");

  // Create the WhatsApp URL with the prepopulated message
  const whatsappURL = `https://wa.me/+918593000876?text=${message}`;

  return (
    <div className='adding'>
      <button id='addButton'>
        {/* Use the whatsappURL as the href for the link */}
        <Link to={whatsappURL} target='_blank' rel='noopener noreferrer'>
          <FaWhatsapp />
        </Link>
      </button>
    </div>
  );
}

export default WhatsAppButton;


