import { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/AdminLogin.css'
const AdminLogin = () => {
    let [email, setEmail] = useState("")
    let [password, setPassword] = useState("")
    let navigate = useNavigate();


    let login = (e) => {
        e.preventDefault();

        let data = { email, password }
        if (email == "devs@weamse.org" && password == "admin@ielts") {
            navigate('/pages')
        } else {
            alert("Inavalid credentials")
        }
    }

    return (
        <div className="adminlogin">
            
                <div className="form_container_card">
                   <div className="admin-title">
                        <h1>Admin Login</h1>
                    </div>
                    <div className="form_input">
                        <div className="datas">
                            <form action="" onSubmit={login} >
                                <div className="log-in">
                                    <p>Please login to continue...</p>
                                </div>
                                <div className="email">
                                    <label id="lab1" htmlFor="">Email</label>
                                    <input type="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="password">
                                    <label id="lab2" htmlFor="">Password</label>
                                    <input type="password" placeholder="Enter Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="click-admin">
                                    <button id="btn1" >Login</button>
                                    <span>Forgot password?</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        
    )
}
export default AdminLogin;