// import React, { useRef, useState } from 'react';
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// import '../styles/Sliders.css';

// // import required modules
// import { Pagination, Navigation } from 'swiper/modules';

// export default function Sliders() {
//   return (
//     <div id='all-sets'>
//       <Swiper
//         slidesPerView={1}
//         spaceBetween={30}
//         loop={true}
//         pagination={{
//           clickable: true,
//         }}
//         navigation={true}
//         modules={[Pagination, Navigation]}
//         className="mySwiper"
//       >
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/1QGhH2i8_U4?si=v6Mk6rtAXcL1KZHQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/UadxnhEgDzo?si=aMLurfAyXjbA0N9S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/tg3bUrtv91k?si=09335mcG8_hL6m6R" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/e0XVOQ5mkDw?si=G1iHNgwZttJEFnY7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/77X5WOHwQ5c?si=PtyTcMacdNi5akfC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/li-M-QQOz4s?si=folSwPZ9fqNjxNP6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/dU-fboZrh1g?si=QaYAHDMBhyc5CJg_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/g2JbtqlTK-g?si=XI1abhRtniRZb113" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
        
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/jMjCE7ZiScY?si=aKwYHp-fdwFbBkXK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//         <SwiperSlide><iframe width="560" height="315" src="https://www.youtube.com/embed/PfU4tvDn7gY?si=DQBHFgEXpr__yN2a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></SwiperSlide>
//       </Swiper>
//     </div>
//   );
// }


import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../styles/Sliders.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

export default function Sliders() {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const closeVideo = () => {
    setSelectedVideo(null);
  };

  return (
    <div id='all-sets'>
      <div>
        <h1 id='test'>Testimonials</h1>
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-1'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/PfU4tvDn7gY?si=qEfdTriFPhT1xa-d"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-2'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/UadxnhEgDzo?si=fg5tvtoJjurzezDC"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-3'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/jMjCE7ZiScY?si=bctsDhgBKOHwaxIC"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-4'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/dU-fboZrh1g?si=EGARIRY5KP9RoPDO"  
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-5'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/li-M-QQOz4s?si=oawA7JRTMxNLnIDo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-6'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/77X5WOHwQ5c?si=SKr7JuX6lVTiZnEU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-7'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/e0XVOQ5mkDw?si=IhppeCb6d0JhmTVq"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-8'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/hrt0DST6F-c?si=KZ1lCIcnAmbPdGFX"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div onClick={() => handleVideoClick('')}>
            <iframe
            id='set-9'
              width="560"
              height="315"
              src="https://www.youtube.com/embed/1QGhH2i8_U4?si=JUlT2DIhaLxbDxWV"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </SwiperSlide>
        {/* Add more SwiperSlides with iframe tags here */}
      </Swiper>

      {/* Display selected video in a modal when clicked */}
      {selectedVideo && (
        <div className="video-modal">
          <div className="video-container">
            <button className="close-button" onClick={closeVideo}>
              Close
            </button>
            <iframe
              width="960" // Adjust the width and height as needed
              height="540"
              src={selectedVideo}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

