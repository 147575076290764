import React, { useState } from 'react';
import '../styles/Menu.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineLogout } from 'react-icons/ai';
import master from '../assets/Images/ielts-master-4-scaled.jpg';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { FaUserCog } from "react-icons/fa";

const Menu = () => {
 
  const [showDropdown, setShowDropdown] = useState(false);

  

  const handleLogoutClick = () => {
    setShowDropdown(!showDropdown);
  };







  const [isToggled, setIsToggled] = useState(false);

  const handleToggleClick = () => {
    setIsToggled(!isToggled);
  };
// ..............................................warning from delete.....................................
  const handleClick = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  };
  // ..............................................warning from delete.....................................

  //..........................................dropdown......................................................

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const inputRef = React.createRef();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsDropdownOpen(false);
    inputRef.current.focus();
  };

  //..........................................dropdown......................................................
    return (
      <div className="platform1">
        <div id="wrapper" className={isToggled ? 'toggled' : ''}>
          {/* Sidebar */}
          <div id="sidebar-wrapper">
            <ul className="sidebar-nav">
              <div id="image-content">
                <img id="image" src={master} alt="" />
              </div>
              <div className="board-sec">
              <Link to=''>
                <li>
                  <span className='part-sec1'>ADMIN <FaUserCog id='icon1'/></span>
                </li>
              </Link>
                <Link to='/pages'>
                <li className='part-sec3'>
                  <span id='pg-sec'>PAGES</span>
                </li>
             </Link>
              <Link to='/menu'>
                <li className='part-sec4'>
                  <span id='menu-sec'>MENU</span>
                </li>
              </Link>
              </div>
            </ul>
          </div>
          {/* /#sidebar-wrapper */}

          {/* Page Content */}
          <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className='disp'>
                  <button
                    onClick={handleToggleClick}
                    className="btn btn-default"
                    id="menu-toggle"
                  >
                    <GiHamburgerMenu />
                  </button>

                  <div id="logout-container">
                    <button id='log-out' onClick={handleLogoutClick}>
                      <AiOutlineLogout />
                    </button>
                    {showDropdown && (
                      <div className="logout-dropdown">
                        {/* Add your logout content here */}
                        <button id='edit-button'>Edit profile</button>
                        <button id='admin-logout'>Logout</button>
                        {/* You can add more items or links here */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


          <div className="main-menu">
            <div className='menu-contents'>
                <div className="menu-head">
                    <h1>MENU</h1>
                </div>
            </div>
          </div>

{/* .......................................dropdown.......................................... */}
          {/* <div className="add-all">
            <div className="add-cont">
              <label htmlFor="">MENU NAME: </label>
              <input type="text" placeholder='' />
              <button>ADD</button>
            </div>
          </div> */}

<div className="add-all">
  <div className="add-cont">
    <label htmlFor="">MENU NAME: </label>
    <div className="input-container">
      <input
        type="text"
        placeholder=""
        onClick={toggleDropdown}
        value={selectedItem}
        ref={inputRef}
        className="dropdown-input" // Add a class to style the input
      />
      <div className="dropdown-icon" onClick={toggleDropdown}>
        {/* Add your dropdown icon here */}
        &#9660; {/* This is a down arrow character as an example */}
      </div>
      {isDropdownOpen && (
        <div className="dropdown">
          {/* Dropdown content goes here */}
          <ul>
            <li id='menu-content1' onClick={() => handleItemClick('menu-content 1')}>menu-content 1</li>
            <li id='menu-content2' onClick={() => handleItemClick('menu-content 2')}>menu-content 2</li>
            <li id='menu-content3' onClick={() => handleItemClick('menu-content 3')}>menu-content 3</li>
          </ul>
        </div>
      )}
    </div>
    <button>ADD</button>
  </div>
</div>





  {/* .......................................dropdown.......................................... */}


          <div className="menu-sec">
              <div className="menu-items">

                
                  <div className='line1'>
                    <div className="cont-1">
                      <div className='adjust1'><h3>Home</h3></div>
                      
                    </div>
                    <div className="menu-dlt1">
                      <button onClick={handleClick}>Delete</button>
                    </div>
                  </div>
            

                <div className='line2'>
                  <div className="cont-2">
                    <div className='adjust2'><h3>Check Your Level</h3></div>
                  </div>
                  <div className="menu-dlt2">
                        <button onClick={handleClick}>Delete</button>
                  </div>
                </div>

                <div className='line3'>
                  <div className="cont-3">
                    <div className='adjust3'><h3>Courses</h3></div>
                  </div>
                  <div className="menu-dlt3">
                      <button onClick={handleClick}>Delete</button>
                  </div>
                </div>

                <div className='line4'>
                  <div className="cont-4">
                    <div className='adjust4'><h3>Features</h3></div>
                  </div>
                  <div className="menu-dlt4">
                      <button onClick={handleClick}>Delete</button>
                  </div>
                </div>

                <div className='line5'>
                  <div className="cont-5">
                    <div className='adjust5'><h3>Testimonials</h3></div>
                  </div>
                  <div className="menu-dlt5">
                      <button onClick={handleClick}>Delete</button>
                  </div>
                </div>

                <div className='line6'>
                  <div className="cont-6">
                    <div className='adjust6'><h3>FAQs</h3></div>
                  </div>
                  <div className="menu-dlt6">
                      <button onClick={handleClick}>Delete</button>
                  </div>
                </div>

                <div className='line7'>
                  <div className="cont-7">
                    <div className='adjust7'><h3>Login</h3></div>
                  </div>
                  <div className="menu-dlt7">
                      <button onClick={handleClick}>Delete</button>
                  </div>
                </div>

              </div>
          </div>
          
          {/* /#page-content-wrapper */}
        </div>
      </div>
    );
  }


export default Menu;
