import React from 'react'
import { useState,useEffect } from 'react'

const BackToTop = () => {

    const [backToTopButton,setBackToTopButton] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () =>{
            if(window.scrollY > 100){
                setBackToTopButton(true)
            } else{
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () =>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

  return (
    <div>
      {
        backToTopButton && (
            <span className='back-to-top-btn' style={{
                fontSize:"16px"
            }}
            onClick={scrollUp}
            >
                Scroll To Top
            </span>
        )
      }
    </div>
  )
}

export default BackToTop
