import React, { useState } from 'react';
import '../styles/Pages.css';
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineLogout } from "react-icons/ai";
import master from '../assets/Images/ielts-master-4-scaled.jpg';
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import 'fontsource-poppins';
import { Link } from 'react-router-dom';
import { FaUserCog } from "react-icons/fa";

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Pages = () => {


  const [showDropdown, setShowDropdown] = useState(false);

  

  const handleLogoutClick = () => {
    setShowDropdown(!showDropdown);
  };



// .....................................................
  const tableRows = [
    {
      Firstname: '1',
      Lastname: 'Home page',
      // Email: 'def@somemail.com',
    },
    // Add more rows here
  ];



    
  const [isToggled, setIsToggled] = useState(false);

  const handleToggleClick = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className='platform2'>
      <div id="wrapper" className={isToggled ? 'toggled' : ''}>
        {/* Sidebar */}
        <div id="sidebar-wrapper">
          <ul className="sidebar-nav">
            <div id='image-content'>
              <img id='image' src={master} alt="" />
            </div>
            <div className='board-sec'>

              <Link to=''>
                <li>
                  <span className='part-sec1'>ADMIN <FaUserCog id='icon1'/></span>
                </li>
              </Link>

             <Link to='/pages'>
                <li className='part-sec3'>
                  <span id='pg-sec'>PAGES</span>
                </li>
             </Link>
              <Link to='/menu'>
                <li className='part-sec4'>
                  <span id='menu-sec'>MENU</span>
                </li>
              </Link>
            </div>
          </ul>
        </div>
        {/* /#sidebar-wrapper */}

        {/* Page Content */}
        {/* ----------------------------------------------------- */}
        {/* <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className='disp'>
                  <button
                    onClick={handleToggleClick}
                    className="btn btn-default"
                    id="menu-toggle"
                  >
                    <GiHamburgerMenu />
                  </button>

                  <button id='log-out'><AiOutlineLogout /></button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className='disp'>
                  <button
                    onClick={handleToggleClick}
                    className="btn btn-default"
                    id="menu-toggle"
                  >
                    <GiHamburgerMenu />
                  </button>

                  <div id="logout-container">
                    <button id='log-out' onClick={handleLogoutClick}>
                      <AiOutlineLogout />
                    </button>
                    {showDropdown && (
                      <div className="logout-dropdown">
                        {/* Add your logout content here */}
                        <button id='edit-button'>Edit profile</button>
                        <button id='admin-logout'>Logout</button>
                        {/* You can add more items or links here */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
{/* -------------------------------------------------------------------------- */}
        <div className="next">
           <div className='segment'>
              <div className="dash">
                  <h1>PAGES</h1>
                  {/* <h4>DASHBOARD<AiOutlineArrowRight/>PAGES</h4> */}
              </div>
              <div className="valid-btn">
                  <Link to='/edit-page'>
                    <div>
                        <button className='valid-btn1'>ADD NEW</button>
                    </div>
                  </Link>
                  {/* <div className='change'>
                      <button id='edit'><FaEdit/></button>
                      <button id='dlt'>< FaTrash /></button>
                  </div> */}
              </div>
           </div>
        </div>

        {/* <div className="tool-head">
            <div className="top-heads">
                <h1>CREATED PAGES</h1>
            </div>
        </div> */}




    
        


        <div className='container-fluid table-use'>
          <div className="container">
        <table className="table">
          <thead>
            <tr>
              <th id='Num'>No:</th>
              <th id='pge'>Pages:</th>
              <th id='action'>
                Actions:{' '}
                {/* <span style={{ float: 'right' }}>
                  { $addButton && <button onClick={handleAdd}>Add New Row</button> }
                </span> */}
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {tableRows.map((row, index) => ( */}
              <tr> 
                <td id='one'>1</td>
                <td id='Hpage'>Home page</td>
                <td>
                  {/* {editingRow === index ? (
                    <div>
                      <input
                        className="form-control input-sm"
                        defaultValue={row.Email}
                      />
                      <button onClick={() => handleAccept(index)}>Accept</button>
                      <button onClick={handleCancel}>Cancel</button>
                    </div>
                  ) : (
                    row.Email
                  )} */}
                
                    <div className="btn-group pull-right all_btns">
                      <button
                        type="button"
                        id="edited-btn"
                        // onClick={() => handleEdit(index)}
                      >
                        <FaEdit/>
                      </button>
                      <button
                        type="button"
                        id="delete-btn"
                        // onClick={() => handleDelete(index)}
                      >
                        <FaTrash/>
                      </button>
                      <button id='preview-btn'><AiFillEye/></button>
                    </div>
                 
                </td>
              </tr>
            {/* ))} */}
          </tbody>
        </table>
      </div>
        </div>

        {/* /#page-content-wrapper */}
      </div>
    </div>
  );
}

export default Pages;
