import React, { useState } from "react";
import "../styles/EditPage.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineLogout } from "react-icons/ai";
import master from "../assets/Images/ielts-master-4-scaled.jpg";
import { Link } from "react-router-dom";
import { FaUserCog } from "react-icons/fa";

import RichTextEditor from "../component/RichTextEditor";

const EditPage = () => {
  // ..........................richTextEditor..........................
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };
  // ..........................richTextEditor..........................

  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogoutClick = () => {
    setShowDropdown(!showDropdown);
  };

  const [isToggled, setIsToggled] = useState(false);

  const handleToggleClick = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="platform1">
      <div id="wrapper" className={isToggled ? "toggled" : ""}>
        {/* Sidebar */}
        <div id="sidebar-wrapper">
          <ul className="sidebar-nav">
            <div id="image-content">
              <img id="image" src={master} alt="" />
            </div>
            <div className="board-sec">
              <Link to="">
                <li>
                  <span className="part-sec1">
                    ADMIN <FaUserCog id="icon1" />
                  </span>
                </li>
              </Link>

              <Link to="/pages">
                <li className="part-sec3">
                  <span id="pg-sec">PAGES</span>
                </li>
              </Link>
              <Link to="/menu">
                <li className="part-sec4">
                  <span id="menu-sec">MENU</span>
                </li>
              </Link>
            </div>
          </ul>
        </div>
        {/* /#sidebar-wrapper */}

        {/* Page Content */}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="disp">
                  <button
                    onClick={handleToggleClick}
                    className="btn btn-default"
                    id="menu-toggle"
                  >
                    <GiHamburgerMenu />
                  </button>

                  <div id="logout-container">
                    <button id="log-out" onClick={handleLogoutClick}>
                      <AiOutlineLogout />
                    </button>
                    {showDropdown && (
                      <div className="logout-dropdown">
                        {/* Add your logout content here */}
                        <button id="edit-button">Edit profile</button>
                        <button id="admin-logout">Logout</button>
                        {/* You can add more items or links here */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-container">
          <div className="table-location">
            <form action="">
              <div class="file-input">
                <div>
                  <div className="banner-label">
                    <label htmlFor="">BANNER IMAGE</label>
                  </div>
                  <input
                    type="file"
                    name="file-input"
                    id="file-input"
                    class="file-input__input"
                  />
                  <label class="file-input__label" for="file-input">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="upload"
                      class="svg-inline--fa fa-upload fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                      ></path>
                    </svg>
                    <span>Upload file</span>
                  </label>
                </div>
              </div>

              <div className="header-imgs">
                <label htmlFor="">HEADER</label>
                <input type="text" placeholder="Enter Banner Heading" />
              </div>

              {/* <div className="teaxt-areas">
                    <label htmlFor="description">DESCRIPTION</label>
                    <textarea name="" id="" cols="50" rows="5"></textarea>
                  </div> */}
             <div className="container-fluid">
               <div className="container">
                  <div className="row">
                    <div
                      className="col-md-12"
                      style={{ margin: "auto", marginTop: "20px" }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <h3 id="details">Description</h3>
                      </div>
                      <div className="rich">
                        <RichTextEditor initialValue="" getValue={getValue} />
                      </div>
                      <br />
                      {/* <div>{value}</div> */}
                    </div>
                  </div>
               </div>
             </div>

              <div className="final">
                <button type="submit">SUBMIT</button>
              </div>
            </form>
          </div>
        </div>

        {/* /#page-content-wrapper */}
      </div>
    </div>
  );
};

export default EditPage;
